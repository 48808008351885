@import 'assets/scss/variables.scss';

.layout-navbar {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    // padding-top: 65px;
    min-height: 100vh;
    transition: all 150ms ease;

    .top-navbar {
        // position: absolute;
        // z-index: 1030;
        // left: 0;
        // right: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: rgba(45, 47, 120, 0.2);
        backdrop-filter: blur(20px);

        .top-navbar-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: inherit;
            align-items: center;
            justify-content: space-between;
        }

        .navbar-brand {
            font-weight: 700;
            font-size: 1.25rem;
            margin-right: 0;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .navbar-toggler {
            i {
                margin: 0;
            }
        }

        .navbar-collapse {
            @media (min-width: 768px) {
                flex-grow: 0;
            }
        }

        .navbar-nav {
            @media (min-width: 992px) {
                align-items: center;
            }
            .nav-item {
                margin-bottom: 0 !important;
            }

            .nav-link {
                font-weight: 500;
                padding-left: 1rem;
                padding-right: 1rem;
                color: #FFF;

                @media (max-width: 991px) {
                    padding-left: .5rem;
                    padding-right: .5rem;
                }

                @media (max-width: 767px) {
                    padding: .875rem 0;
                    color: #FFF !important;
                }
            }

            .btn {
                font-size: 18px;
            }

            .dropdown {
                .dropdown-menu {
                    z-index: 1030;
                    opacity: 0;
                    border-color: $gray5;
    
                    &.show {
                        opacity: 1;
                        animation: fadeIn 150ms ease;
                    }
    
                    .dropdown-item {
                        padding-left: var(--bs-gutter-x, 0.75rem);
                        padding-right: var(--bs-gutter-x, 0.75rem);
                    }
    
                    .dropdown-divider {
                        border-color: $gray4;
                    }

                    @media (min-width: 992px) {
                        border: none;
                        margin-top: 1rem;
                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                    }
    
                }
            }
        }

        .navbar-user-dropdown {
            .dropdown-toggle {
                width: 36px;
                height: 36px;
                padding: 0;
                border-radius: 50%;
                overflow: hidden;

                &::after {
                    content: '' !important;
                    display: none !important;
                }

                img {
                    width: 36px;
                    height: 36px;
                }
            }

            .dropdown-menu {
                width: 250px;
                border: none;
                margin-top: 1rem;

                @media (max-width: 992px) {
                    position: fixed;
                    top: 44px;
                    right: .5rem;
                }
            }

            .navbar-user {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-left: var(--bs-gutter-x, 0.75rem);
                padding-right: var(--bs-gutter-x, 0.75rem);

                .navbar-user-avatar {
                    flex: 0 0 36px;
                    width: 36px;
                    height: 36px;
                    padding: 0;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: .5rem;

                    img {
                        width: 36px;
                        height: 36px;
                    }
                }

                .navbar-user-info {
                    flex: 1 1;
                    width: calc(100% - 36px - .5rem);
                    text-align: left;

                    .navbar-user-fullname {
                        font-weight: 500;
                        line-height: 1.3;
                        margin-bottom: 0;
                    }
                    
                    .navbar-user-username {
                        color: $gray4;
                        font-size: .75rem;
                        line-height: 1.3;
                        margin-bottom: 0;
                        max-width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .site-content {
        flex-grow: 1;
    }

    .site-footer {
        // padding-top: 4rem;
        background-color: var(--bs-primary);

        .footer-brand {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 64px;
            width: 64px;
            overflow: hidden;
            font-weight: 600;
            font-size: 1.5rem;
            text-align: center;
            color: var(--bs-white);
            background-color: var(--bs-primary);
            border-radius: 16px;
            margin-right: auto;
            margin-bottom: 2rem;
        }

        h5 {
            color: #FFF;
        }

        .footer-links {
            list-style: none;
            padding-inline-start: 0;
            
            li {
                margin-top: .625rem;

                a {
                    color: #FFF;
                    text-decoration: none;
                }
            }
        }

        .site-copyright {
            color: #FFF;
            // border-top: .5px solid rgba(255, 255, 255, 0.15);
            // margin-top: 3rem;
        }
    }
}