@import 'assets/scss/variables.scss';

@keyframes bounce {
    0%      { transform: scale(1); }
    50%     { transform: scale(1.05); }
    100%    { transform: scale(1); }
}

.welcome-section {
    color: #FFF;
    position: relative;
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-color: var(--bs-primary);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 767px) {
        padding-top: 6rem;
        padding-bottom: 3rem;
    }

    .header-content {
        position: relative;
        z-index: 2;
    }

    .header-slider {
        margin: 0 -16px;

        .swiper-button-prev,
        .swiper-button-next {
            width: 44px;
            background-color: #FFF;
            border-radius: 50%;

            &::after {
                color: #000;
            }

            @media (min-width: 767px) {
                width: 64px;
                height: 64px;
                top: calc(50% - 32px);             
            }
        }

        .swiper-button-prev {
            left: 1rem;
        }

        .swiper-button-next {
            right: 1rem;
        }

        @media (min-width: 767px) {
            .swiper-button-prev {
                left: 2rem;
            }
    
            .swiper-button-next {
                right: 2rem;
            }              
        }
    }

    .header-slide-item {
        padding: 16px;

        @media (max-width: 991px) {
            padding: 16px 0;
        }

        .header-slide-item-wrapper {
            position: relative;
            background: linear-gradient(to top right, rgba(45, 47, 120, .7) 0%, rgba(17, 91, 158, .7) 100%);
            padding: 2rem 6rem 0;
            border-radius: 2rem;
            box-shadow: 4px 4px 16px rgba(45, 47, 120, .45);

            @media (min-width: 992px) {
                min-height: 670px;
            }

            @media (max-width: 991px) {
                padding: 2rem 2rem 0;
                // min-height: 560px;
                border-radius: 0;
            }

            @media (max-width: 767px) {
                padding-bottom: 3rem;
            }

            .row {
                position: relative;
                z-index: 1;
                height: 100%;
            }
        }

        .header-slide-item-title {
            span {
                position: absolute;
                z-index: -1;
                opacity: 0;
            }
        }

        .header-slide-item-subtitle {
            font-family: 'Siracha', sans-serif;
            font-size: 1.5rem;
            margin-bottom: 3rem;

            @media (max-width: 991px) {
                font-size: 1.25rem;
            }
        }

        .header-slide-item-description {
            margin-top: 3rem;
            margin-bottom: 1.5rem;

            b {
                color: var(--bs-info);
            }
        }

        .btn-genz {
            animation: bounce 1.5s infinite ease;
        }

        .btn-header-join {
            margin-top: -.5rem;
        }

        .header-globe {
            position: absolute;
            z-index: 0;

            &.globe-1 {
                img {
                    width: 10vw;
                    height: auto;
                }
            }
            
            &.globe-2 {
                img {
                    width: 14vw;
                    height: auto;
                }
            }

            &.globe-3 {
                img {
                    width: 10vw;
                    height: auto;
                }
            }
        }

        .header-slide-item-1 {
            .globe-1 {
                bottom: 48px;
                left: 48px;
            }

            .globe-2 {
                top: 16px;
                left: 48px;
            }

            .globe-3 {
                top: 32px;
                right: 18%;
            }
        }

        .header-slide-item-2 {
            .globe-1 {
                bottom: -16px;
                left: 72px;
            }

            .globe-2 {
                top: 16px;
                left: 45%;
            }

            .globe-3 {
                bottom: 56px;
                right:32px;
            }
        }

        .header-slide-item-3 {
            .globe-1 {
                bottom: 48px;
                left: 38%;
            }

            .globe-2 {
                top: 16px;
                left: 48px;
            }

            .globe-3 {
                top: 32px;
                right: 18%;
            }
        }
    }
}

.section-bg-wrapper {
    position: relative;
    z-index: 1;
    background: linear-gradient(270deg, rgb(82, 113, 255), rgb(94, 23, 235));
}

.gift-section {
    color: #FFF;
    position: relative;

    .sec-header {
        .sec-title {
            position: relative;
            max-width: 720px;
            margin-left: auto;
            margin-right: auto;
            text-shadow: 0 0 8px rgba(4, 54, 101, 0.9);

            .title-element-1 {
                position: absolute;
                top: calc(50% - 40px);
                right: -68px;
                width: 80px;
                height: 80px;

                @media (max-width: 767px) {
                    top: -16px;
                    left: 0;
                    right: unset;
                }
            }

            .title-element-2 {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100px;
                height: 100px;
            }
        }
    }

    .gift-section-bg {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .row {
            height: 100%;
        }

        .gift-section-bg-img {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 31%;
        }
    }

    .container-xl {
        position: relative;
        z-index: 1;
    }

    .gift-slider {
        margin-left: -.75rem;
        margin-right: -.75rem;
    }

    .gift-item {
        // display: flex;
        width: 100%;
        padding: 12px;

        img {
            object-fit: cover;
            border-radius: .5rem;
            box-shadow: 0 0 12px rgba(4, 54, 101, 0.9);
        }
    }

    .gift-quotes {
        position: relative;

        p {
            position: relative;
            z-index: 1;
            text-shadow: 2px 2px 8px rgba(45, 47, 120, .45);
        }

        .gift-quotes-element {
            position: absolute;
            z-index: 0;
            top: -5px;
            left: calc(50% - 180px);
            height: 110px;
            width: auto;
        }
    }
}

.store-section {
    color: #FFF;
    padding-top: 6rem;

    .store-section-bg {
        position: absolute;
        z-index: 0;
        top: 4rem;
        bottom: 6rem;
        left: 0;
        right: 0;

        .row {
            height: 100%;
        }

        .store-section-bg-img {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 25%;
        }
    }

    .container-xl {
        position: relative;
        z-index: 1;
    }

    .sec-header {
        &.desktop {
            margin-top: 4rem;
            margin-bottom: 4rem;
        }
        .sec-title {
            font-size: 2.5rem;
            position: relative;
            display: inline-block;
            background-image: linear-gradient(90deg, rgb(255, 255, 255), rgb(92, 225, 230));
            -webkit-background-clip: text;
            -webkit-text-stroke-width: 0px;
            -webkit-text-fill-color: transparent;
            transform: rotate(-3deg);

            .title-element-1 {
                position: absolute;
                left: -60px;
                top: calc(50% - 35px);
                width: 50px;
                height: 70px;
            }
        }

        .sec-description {
            font-weight: 400;
        }
    }

    .store-item {
        text-align: center;

        @media (max-width: 767px) {
            // padding: 1.5rem;
            // border-radius: 1.5rem;
            // background-color: rgba(17, 91, 158, .7);
            p {
                margin-bottom: 2rem;
            }
        }

        h5 {
            color: var(--bs-info);
            text-transform: uppercase;
        }
    }
}

.contest-section {
    .sec-header {
        .sec-title {
            position: relative;
            display: inline-block;
            line-height: 6rem;
            text-align: right;
            transform: rotate(-10deg);

            @media (max-width: 767px) {
                font-size: 3rem;

                .title-element-1 {
                    right: 5% !important;
                }
            }

            span {
                position: relative;
                z-index: 1;
                text-shadow: 0 0 8px rgba(4, 54, 101, 0.9);
            }

            .title-element-1 {
                position: absolute;
                z-index: 0;
                right: 26%;
                top: calc(50% - 70px);
                width: 140px;
                height: 140px;
            }

            .title-element-2 {
                position: absolute;
                z-index: 0;
                top: 5rem;
                left: 7%;
                height: 32px;
            }

            .title-element-3 {
                position: absolute;
                z-index: 0;
                right: -48px;
                top: -24px;
                width: 160px;
                height: auto;
            }
        }

        .sec-description {
            margin-left: auto;
            margin-right: auto;

            @media (min-width: 768px) {
                width: 50%;
                max-width: 400px;
            }
        }
    }

    .contest-item {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .contest-item-img {
            img {
                border-radius: 1rem;
            }
        }

        .btn-genz {
            animation: bounce 1.5s infinite ease;

            @media (max-width: 767px) {
                width: 90% !important;
            }
        }
    }
}

.video-section {
    padding-top: 6rem;
    background: linear-gradient(270deg, rgb(51, 33, 119), rgb(189, 102, 246));
    
    .sec-header {
        .sec-title {
            @media (max-width: 767px) {
                font-size: 3rem;
            }
        }
    }

    .video-player {
        width: 100%;
        border-radius: 1rem;
        overflow: hidden;
    }

    .video-playlist {
        width: 100%;
        height: calc(120px + 2rem);
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 1rem;
        background-color: rgba(140, 82, 255, .5);
        border-radius: 1rem;
        white-space: nowrap;

        .video-playlist-item {
            display: inline-block;
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 1rem;
            }

            img {
                border-radius: .5rem;
                object-fit: cover;
            }
        }
    }
}

.page-background-sticky {
    position: fixed;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.join-section {
    padding-top: 6rem;
    background-color: rgba(65, 8, 117, .35);

    .join-section-wrapper {
        padding: 2rem;
        border-radius: 1rem;
        background-color: rgba(250, 243, 255, .95);

        @media (max-width: 767px) {
            padding: 1rem;
        }
    }

    .sec-header {
        .sec-title {
            text-align: right;
            text-shadow: 2px 2px 10px rgb(60, 6, 94);
            transform: rotate(-10deg);

            .text-info {
                font-size: 2rem;
                text-shadow: none;
            }

            .title-element-1 {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                width: 140px;
                height: 24px;
                margin-right: .5rem;
            }

            .title-element-2 {
                position: relative;
                display: inline-block;
                vertical-align: baseline;
                width: 50px;
                height: 50px;
                margin-left: .2rem;
            }
            
            @media (max-width: 767px) {
                font-size: 2.5rem;
                transform: none;
                text-align: center;

                .text-info {
                    font-size: 1.5rem;
                }

                .title-element-1 {
                    width: 80px;
                    vertical-align: 0px;
                }

                .title-element-2 {
                    width: 30px;
                    vertical-align: -10px;
                }
            }
        }
    }
}

.social-section {
    padding-top: 6rem;
    padding-bottom: 10rem;
    background: linear-gradient(270deg, rgb(51, 33, 119), rgb(189, 102, 246));

    .sec-header {
        .sec-title {
            font-size: 2rem;
            text-shadow: 2px 2px 10px rgb(60, 6, 94);
        }
    }
}

.cos-con-modal {
    color: #000;
    font-size: .875rem;
    font-weight: 600;

    h2 {
        color: var(--bs-primary);
        margin-bottom: 1.5rem;
    }

    td {
        font-size: .875rem;
        font-weight: 600;
        background-color: transparent !important;

        &:first-child {
            white-space: nowrap;
            color: var(--bs-primary);
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    ul li:not(:last-child), ol li:not(:last-child) {
        margin-bottom: .25rem;
    }
}